
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
    font-family: "Playfair Display SC";
}

.Playfair Display SC {
    font-family: "Playfair Display SC";
}


.bodycustomtext {
    max-width: 1024px;
    text-align: justify;
    text-shadow: 0px 0px 24px rgba(0, 0, 0, 1);
    text-justify: auto;
}

.bodycustomtextonplex {
    max-width: 1024px;
    text-align: center;
    text-shadow: 0px 0px 17px rgba(255, 255, 255, 1);
    text-justify: auto;
}

.lettercustomtext {
    max-width: 1024px;
    font-size: 18px;
    font-style: italic;
    font-family: "Playfair Display SC";
    text-align: center;
    text-justify: auto;
}

.lettercustomtitle {
    font-style: italic;
    font-family: cursive;
}

.signaturecustomtext {
    font-size: 24px;
    font-style: italic;
    font-family: cursive;
}

.logo-big-topbar {
    background: radial-gradient(circle, rgba(255,255,255,0.3) 32%, rgba(255,255,255,0) 70%);
    background-repeat: no-repeat;
    width: 168px;
    height: 171px;
}


.logo-big-topbar-glow {
    width: 100%;
    height: 100%;
    background-image: url('./assets/logo-only-grey.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.logo-small-topbar {
    background: radial-gradient(circle, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0) 66%);
    background-repeat: no-repeat;
    width: 63px;
    height: 80px;
    margin-left: 28px;
}

.logo-small-topbar-glow {
    width: 100%;
    height: 100%;
    background-image: url('./assets/logo-only-grey.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.logo-footer {
    background-image: url('./assets/logo-only-white.svg');
    background-position: center;
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
}

.button-glow {
    background: radial-gradient(circle, rgba(255,255,255,0.2) 32%, rgba(255,255,255,0) 70%);
    width: 100%;
    height: 100%;
}

.parallax-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgb(78,78,78);
    background: linear-gradient(180deg, rgba(78,78,78,1) 0%, rgba(164,148,101,1) 100%);
}

.parallax-layer-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets/paralax-1.webp');
    background-size: cover;
    background-position: left;
}

.parallax-layer-2 {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('./assets/paralax-2.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    /*animation: flickering 3s infinite;*/
    animation: 1s ease-out 0s 1 appear-from-right
}

.parallax-layer-3 {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('./assets/paralax-3.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    /*animation: flickering 3s infinite;*/
    animation: 1s ease-out 0s 1 appear-from-left
}

.parallax-layer-4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.topbar-fixed {
    position: fixed;
    width: 100%;
}

.animate-on-hover {
    transition: all 0.3s ease !important;
}

.custombutton {
    border: 1px solid white;
    border-radius: 10px;
}

    .custombutton :hover {
        background-color: darkgrey;
        color: white;
        text-shadow: 4px 4px 5px rgba(66, 68, 90, 1);
    }





.dark-shadow {
    text-shadow: 4px 4px 5px rgba(66, 68, 90, 1);
}

.bouncing-icon-container {
    animation: shake-anim 3s infinite;
}

.white-shadow {
    text-shadow: 4px 4px 5px rgba(255, 255, 255, 0.1);
}


.translucent-container {
    backdrop-filter: blur(15px);
    background-color: rgba(255,255,255,0.7);
    padding: 8px;
    z-index: 22;
    border-radius: 4px;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

.card-item-container {
}

.card-item {
    transition: 0.3s;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

    .card-item:hover {
        filter: contrast(150%);
        color: white;
    }

.topbar-logo-container {
    background-color: rgba(236,236,236,0.3);
    clip-path: polygon(100% 0, 68% 100%, 0 100%, 0 0);
    padding-top: 20px;
    height: 210px;
    z-index: 23;
    backdrop-filter: blur(5px);
}


.topbar-buttons-container {
    backdrop-filter: blur(5px);
    background-color: rgba(236,236,236,0.5);
    padding: 8px;
    padding-left: 43px;
    z-index: 22;
    height: 80px;
    transition: all .5s;
}

.topbar-combined-container {
    backdrop-filter: blur(5px);
    background-color: rgba(236,236,236,0.5);
    padding: 8px;
    z-index: 22;
    padding-left: 43px;
    width: 100vw;
    height: 80px;
    transition: all .5s;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    -moz-box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
}

.circle-avatar-container {
   
    background-color: lightgray;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    -moz-box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    border-radius: 150px;
    width: 200px;
    height: 200px;
    padding-top: 35px;
    padding-left: 35px;
}


.circle-avatar-container-sm {
 margin-top: -80px;
    background-color: lightgray;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    -moz-box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    border-radius: 150px;
    width: 100px;
    height: 100px;
    padding-top: 15px;
    padding-left: 16px;
}

.suitcase {
    background-image: url('./assets/suitcase.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    opacity: 0.8;
}

.facebook {
    background-image: url('./assets/fb.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

.child {
    background-image: url('./assets/child.svg');
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 120px;
    height: 150px;
    opacity: 0.8;
}

.flag {
    background-color: white;
    border: 1px solid grey;
    box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.57);
    cursor: pointer;
    transition: all .5s;
    z-index: 999999;
}

    .flag :hover {
        border: 1px solid white;
        box-shadow: 0px 4px 5px 0px rgba(66, 68, 90, 0.9);
        filter: brightness(150%);
    }

.pl-flag {
    background-image: url('./assets/pl.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.en-flag {
    background-image: url('./assets/en.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.ru-flag {
    background-image: url('./assets/ru.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-color: white;
}

.handcuffs-bg {
    position: absolute;
    background-image: url('./assets/handcuffs.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    opacity: 0.1;
}

.appear-anim {
    animation: fadeInAnimation ease 3s;
  
}
.language-modal-container {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.insured-bg {
    position: absolute;
    background-image: url('./assets/insured.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    opacity: 0.1;
}

.family-bg {
    position: absolute;
    background-image: url('./assets/family.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    opacity: 0.1;
}

.globe-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url('./assets/globe.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
    opacity: 0.1;
    z-index: -1;
}

.document-bg {
    position: absolute;
    background-image: url('./assets/document.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 200px;
    height: 300px;
    opacity: 0.1;
}

.section-1-splash {
    background-image: url('./assets/bg3.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100px;
    border: 2px solid grey;
    border-radius: 5px;
}

.section-2-splash {
    background-image: url('./assets/FamilyCourtImage.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100px;
    border: 2px solid grey;
    border-radius: 5px;
}

.section-3-splash {
    background-image: url('./assets/bg5.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100px;
    border: 2px solid grey;
    border-radius: 5px;
}

.section-4-splash {
    background-image: url('./assets/coins.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100px;
    border: 2px solid grey;
    border-radius: 5px;
}


.contact-tile {
    width: 100%;
    height: 200px;
    border: 2px solid grey;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    transition: all .5s;
}

    .contact-tile :hover {
        filter: contrast(130%);
    }

.contact-tile-phone {
    background-image: url('./assets/retrophone.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contact-tile-mail {
    background-image: url('./assets/email.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contact-tile-post {
    background-image: url('./assets/letterBox.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contact-tile-text {
    backdrop-filter: blur(5px);
    background-color: rgba(36,36,36,0.2);
    width: 100%;
    text-align: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.colage-preview {
    background-image: url('./assets/colage.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 300px;
    border-radius: 10px;
    border: 2px solid grey;
    cursor: pointer;
    transition: all .5s;
}

    .colage-preview :hover {
        background-size: cover;
        background-position: center;
        border: 2px solid lightgrey;
        filter: contrast(130%);
    }

.navbar-menu-button {
    font-size: 13px;
    width: 100px !important;
    transition: 0.3s;
    color: rgba(0, 0, 0, 0.54);
}

    .navbar-menu-button :hover {
        background-color: darkgrey;
        background-image: radial-gradient(ellipse farthest-corner at 32px 32px, rgba(30, 30, 30, 0.2) 0%, rgba(70, 70, 70, 0.1) );
        border-radius: 5px;
        color: white;
        transition: 0.3s;
    }


.blur-section-borders {
    width: 100%;
    height: 100px;
    background-color: rgba(236,236,236,0.3);
    margin-top: -50px;
    margin-bottom: -50px;
    z-index: 22;
    backdrop-filter: blur(15px);
    mask: linear-gradient(transparent, black, transparent);
}



.gradient-section {
    width: 100%;
    height: 100%;
    background: rgb(78,78,78);
    background: linear-gradient(180deg, rgba(78,78,78,1) 0%, rgba(164,148,101,1) 100%);
}

.fingerprint-pattern-foreground {
    width: 100%;
    height: 100%;
    background-image: url('./assets/fingerprint.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.seamless-pattern-foreground {
    width: 100%;
    height: 100%;
    background-image: url('./assets/seamless.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.divider-pattern-foregound {
    width: 100%;
    height: 50px;
    margin-top: 62px;
    background-image: url('./assets/divider.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.divider-dark-pattern-foregound {
    width: 300px;
    height: 50px;
    margin-top: 62px;
    background-image: url('./assets/divider-dark.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.greek-circle-pattern-foreground {
    width: 200px;
    height: 200px;
    background-image: url('./assets/greek.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

}

.profile-image1 {
    text-align: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: url('./assets/profile1.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.profile-image2 {
    border-radius: 50%;
    background-image: url('./assets/profile-pic-2.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.side-profile {
    position: absolute;
    bottom: 0;
    background-image: url('./assets/profile2.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    z-index: -1;
}

.second-background {
    background-image: url('./assets/bg2.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.third-background {
    background-image: url('./assets/workspace.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.fourth-background {
    background-image: url('./assets/girl.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.footer {
    background-color: rgba(78,78,78,1);
    color: white;
}


.chevron-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
}

.chevron {
    position: absolute;
    width: 50px;
    height: 5px;
    opacity: 0;
    animation: move-chevron 4s ease-out infinite;
}

    .chevron:first-child {
        animation: move-chevron 4s ease-out 2s infinite;
    }

    .chevron:nth-child(2) {
        animation: move-chevron 4s ease-out 2s infinite;
    }

    .chevron:before,
    .chevron:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        background: rgba(255,255,255,0.5);
    }

    .chevron:before {
        left: 0;
        transform: skewY(30deg);
    }

    .chevron:after {
        right: 0;
        width: 50%;
        transform: skewY(-30deg);
    }


@keyframes move-chevron {
    25% {
        opacity: 1;
    }

    33.3% {
        opacity: 1;
        transform: translateY(20px);
    }

    66.6% {
        opacity: 1;
        transform: translateY(40px);
    }

    100% {
        opacity: 0;
        transform: translateY(60px) scale(0.35);
    }
}


/* ---------- Fog ---------- */

#foglayer_01 {
    position: absolute;
    height: 100%;
    width: 200%;
    overflow: hidden;
    -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
    -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
    animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}

    #foglayer_01 .image01, #foglayer_01 .image02 {
        background-image: url('./assets/fog1.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    #foglayer_01 .image01, #foglayer_01 .image02,
    #foglayer_03 .image02 {
        float: left;
        height: 100%;
        width: 50%;
    }


@keyframes flickering {
    0% {
        filter: brightness(100%);
    }

    20% {
        filter: brightness(80%);
    }

    40% {
        filter: brightness(90%);
    }

    50% {
        filter: brightness(70%);
    }
    /* Finish changes by here */
    50% {
        filter: brightness(90%);
    }
    /* Between 20% andseconddddddng changes */
    100% {
        filter: brightness(100%);
    }
}

.appear-2 {
    animation: 1s ease-out 0s 1 appear-zoom-out;
}



.appear-1 {
    animation: 0.5s ease-out 0s 1 appear-zoom-out;
}

@keyframes appear-zoom-out {
    0% {
        transform: scale(1.1);
        opacity: 0;
    }

   
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes appear-from-right {
    0% {
        transform: translateX(20vw);
    }

    80% {
        transform: translateX(5vw);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes appear-from-left {
    0% {
        transform: translateX(-20vw);
    }

    80% {
        transform: translateX(-5vw);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes foglayer_moveme {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

@-webkit-keyframes foglayer_moveme {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}


.bottom-divider {
    border-bottom: 2px solid white;
}


.lawdivider-container {
    align-content: center;
    text-align: center;
    margin-top: 5px;
    width: 55vw;
    position: relative;
}

.lawdivider {
    height: 1px;
    background-color: rgba(255,255,255, 0.7);
    max-width: 550px;
    box-shadow: 0 0 4px white;
    margin: 0px auto 0;
}

    .lawdivider:after {
        content: '';
    }

.lawdivider-dot {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 100%;
    left: calc(50% - 15px);
    margin-bottom: -15px;
    border-radius: 100%;
    box-shadow: 0 0 5px white;
    background: white;
}

.lawdivider-paragraph {
    color: darkgray;
    font-weight: 800;
    font-size: 22px;
    margin-top: -2px;
}

@keyframes shake-anim {
    0% {
    }

    15% {
        transform: translateY(-5px);
        transform: scale(1.2)
    }

    20% {
        transform: translateY(-5px) rotate(18deg);
    }

    30% {
        transform: translateY(-9px) rotate(-18deg);
    }

    45% {
        transform: translateY(-9px) rotate(18deg);
    }

    50% {
        transform: translateY(-9px) rotate(-18deg);
    }

    60% {
        transform: translateY(0) rotate(0);
        transform: scale(1);
    }
}
.glowing-text {
    animation: glow 1.1s infinite;
}


@keyframes glow {
    0% {
        transform: scale(1.2);
        color: red;
        text-shadow: 4px 4px 26px rgba(255, 0, 2, 1);
    }

    20% {
        transform: scale(1.1);
        color: black;
        text-shadow: 4px 4px 26px rgba(0, 0, 0, 1);
    }

    30% {
        transform: scale(1.2);
        color: red;
        text-shadow: 4px 4px 26px rgba(255, 0, 2, 1);
    }



    100% {
        transform: scale(1);
        color: black;
        text-shadow: 4px 4px 26px rgba(0, 0, 0, 1);
    }
}